// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-lawyer-js": () => import("./../src/templates/lawyer.js" /* webpackChunkName: "component---src-templates-lawyer-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-areas-of-law-js": () => import("./../src/pages/areas-of-law.js" /* webpackChunkName: "component---src-pages-areas-of-law-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lawyers-js": () => import("./../src/pages/lawyers.js" /* webpackChunkName: "component---src-pages-lawyers-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

